<template>

  <div class="app-metadata-detail">

    <section class="metadata-content">

      <!-- 标题 -->

      <p id="metadata-title">{{ detail.name }}
      </p>

      <!-- 数据贡献者 -->

      <p id="metadata-contributor">Data contributors:&emsp;{{ detail.contributor }}
      </p>

      <!-- 数据信息条 -->
      <ul id="metadata-detail-bar">

        <li v-for="(item, index) in bar"
          :key="index">

          <i
            :class="['icon', 'iconfont', item.icon]"
            :style="{ 'background-color': item.color }">
          </i>

          <p>{{ item.value }}&nbsp;{{ item.suffix }}
          </p>

          <p>{{ item.label }}
          </p>

        </li>

      </ul>

      <!-- 数据信息卡 -->
      <ul id="metadata-detail-card">

        <li v-for="(item, index) in card"
          :key="index">

          <span class="label">{{ item.label }}
          </span>

          <a v-if="item.link"
           :href="item.value"
           target="_blank">

            <span>{{ item.value }}
            </span>

            <i class="el-icon-link">
            </i>

          </a>

          <span v-else
            class="value">{{ item.value }}
          </span>

        </li>

      </ul>

      <!-- 数据摘要 -->
      <div id="metadata-summary">

        <div class="metadata-section-header">

          <span class="metadata-section-cude">■
          </span>

          <span class="metadata-subtitle">Abstract
          </span>

        </div>

        <div v-html="detail.summary"
          class="metadata-summary-content">
        </div>

        <!-- <div class="metadata-controls">

          <el-button
            size="small"
            @click="addToBasket()">加入到数据篮
          </el-button>

        </div> -->

      </div>

      <!-- 基本信息 -->
      <div id="metadata-detail-base">

        <div class="metadata-section-header">

          <span class="metadata-section-cude">■
          </span>

          <span class="metadata-subtitle">Metadata
          </span>

        </div>

        <ul>

          <li v-for="(item, index) in base"
            :key="index">

            <span class="label">{{ item.label }}:&emsp;
            </span>

            <span class="value">{{ item.value }}
            </span>

          </li>

        </ul>

        <!-- 轮播图 -->
        <div class="metadata-carousel">

          <el-carousel
            trigger="click"
            height="139px">

            <el-carousel-item v-for="(item, index) in carousel"
              :key="index">

              <img
                alt="CAROUSEL_ITEM"
                height="139"
                :src="item.path"
                width="180">

            </el-carousel-item>

          </el-carousel>

        </div>

      </div>

      <!-- 数据联系信息 -->
      <div id="metadata-detail-contact">

        <div class="metadata-section-header">

          <span class="metadata-section-cude">■
          </span>

          <span class="metadata-subtitle">Contact
          </span>

        </div>

        <ul>

          <li v-for="(item, index) in contact"
            :key="index">

            <span class="label">{{ item.label }}:&emsp;
            </span>

            <span class="value">{{ item.value }}
            </span>

          </li>

        </ul>

        <!-- 缩略图 -->
        <div
          class="metadata-thumbnail"
          id="thumbnail">
        </div>

      </div>

      <!-- 数据使用申明 -->
      <div id="metadata-usage-statement">

          <div class="metadata-section-header">

            <span class="metadata-section-cude">■
            </span>

            <span class="metadata-subtitle">Data usage statement
            </span>

          </div>

        <ul>

          <li v-for="(item, index) in statement"
            :key="index">

            <div class="subtitle">{{ item.label }}
            </div>

            <p v-for="(text, tid) in item.value"
              :key="tid">{{ text }}
            </p>

          </li>

        </ul>

      </div>

      <!-- Tabs -->
      <div id="metadata-tabs">

        <ul class="tabs-header">

          <li v-for="(item, index) in tabs.list"
            :class="activeTab(item.key)"
            :key="index"
            @click="toggleTab(item.key)">{{ item.label }}
          </li>

        </ul>

        <!-- 数据详细描述 -->
        <div v-show="tabs.current === 'description'"
          id="metadata-description">

          <ul>

            <li v-for="(item, index) in description"
              :key="index">

              <div class="subtitle">{{ item.label }}
              </div>

              <p>{{ item.value }}
              </p>

            </li>

          </ul>

        </div>

        <!-- 扩展信息 -->
        <div v-show="tabs.current === 'extra'"
          id="metadata-usage-statement"
          class="metadata-usage-statement">

          <ul>

            <li v-for="(item, index) in extra"
              :key="index">

              <div class="subtitle">{{ item.label }}
              </div>

              <p>{{ item.value }}
              </p>

            </li>

          </ul>

        </div>

        <!-- 数据附件 -->
        <!-- <div v-show="tabs.current === 'attachment'"
          id="metadata-attachment">

          <ul class="metadata-attachment-list">

            <li v-for="(item, index) in attachment"
              :key="index">

              <strong>{{ item.label }}
              </strong>

              <p>

                <span>{{ item.name }}
                </span>

                <a
                  :download="item.name"
                  :href="item.url"
                  target="_blank">下载
                </a>

              </p>

            </li>

          </ul>

        </div> -->

        <!-- 相关成果 -->
        <div v-show="tabs.current === 'results'"
          id="related-results">

          <el-table
            :data="results.list"
            size="mini">

            <el-table-column
              type="index"
              :width="40">
            </el-table-column>

            <el-table-column v-for="(item, index) in results.columns"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              :show-overflow-tooltip="item.tooltip"
              :width="item.width">
            </el-table-column>

            <el-table-column
              label="control"
              :width="80">

              <el-button
                plain
                size="mini">preview
              </el-button>

            </el-table-column>

          </el-table>

        </div>

        <!-- 相关文献 -->
        <div v-show="tabs.current === 'related-literature'"
          id="metadata-related-literature">

          <template v-if="literature.length">

            <p v-for="(item, index) in literature"
              :key="index">{{ item }}
            </p>

          </template>

          <p v-else
            class="empty">There is no relevant literature
          </p>

        </div>

      </div>

      <!-- 数据下载 -->
      <!-- <div id="dataset-download">

        <div class="metadata-section-header">

          <span class="metadata-section-icon">&nbsp;
          </span>

          <span class="metadata-subtitle">数据下载
          </span>

        </div>

        <div class="download-toolbar">

          <ul class="download-path">

            <li v-for="(item, index) in download.path"
              :key="index">{{ item.label }}
            </li>

          </ul>

          <div class="download-controls">

            <el-button
              size="mini">下载当前目录文件
            </el-button>

            <el-button
              size="mini">下载选中文件
            </el-button>

          </div>

        </div>

        <div class="download-list">

          <el-checkbox @change="toggleFileAll">

            <span class="name">名称
            </span>

            <span class="size">大小(M)
            </span>

            <span class="type">类型
            </span>

            <span class="time">最后修改时间
            </span>

          </el-checkbox>

          <el-checkbox-group v-model="download.checkeds">

            <template v-for="(item, index) in download.list">

              <el-checkbox v-if="item.checkbox"
                :key="index"
                :label="item.name">

                <span class="name">

                  <img
                    alt="ICON_FOLDER"
                    class="icon"
                    src="/static/img/data/files/file.png">

                  <span class="base">{{ item.name }}
                  </span>

                  <span class="popup">{{ item.name }}
                  </span>

                </span>

                <span class="size">{{ item.size }}
                </span>

                <span class="type">{{ item.type }}
                </span>

                <span class="time">{{ item.time }}
                </span>

              </el-checkbox>

              <div v-else
                class="download-folder"
                :key="index">

                <span class="name">

                  <img
                    alt="ICON_FOLDER"
                    class="icon"
                    src="/static/img/data/files/folder.png">

                  <span>{{ item.name }}
                  </span>

                </span>

                <span class="size">{{ item.size }}
                </span>

                <span class="type">{{ item.type }}
                </span>

                <span class="time">{{ item.time }}
                </span>

              </div>

            </template>

          </el-checkbox-group>

          <div class="download-more">

            <span>更多选项
            </span>

            <i class="el-icon-caret-bottom">
            </i>

          </div>

        </div>

      </div> -->
      <app-files
        ref="app-files"
        :url="ftpDownloadUrl">
      </app-files>

      <!-- 平台申明 -->
      <div id="platform-statement">

        <div class="metadata-section-header">

          <span class="metadata-section-icon">&nbsp;
          </span>

          <span class="metadata-subtitle">Platform statement
          </span>

        </div>

        <ul>

          <li>The platform owns the copyright and other intellectual property rights of all the data products or services published by it or contributed by data contributors and the relevant information published on the platform, which are protected by law.
          </li>

          <li>Without the written permission of the platform, no unit or individual is allowed to copy, modify, transcribe, disseminate and sell any part of the above data products, services, information and materials in any way or for any reason.
          </li>

          <li>Any infringement of the copyright and other intellectual property rights of the platform, the platform will be investigated for its legal responsibility in accordance with the law, hereby solemnly declare!
          </li>

        </ul>

      </div>

    </section>

    <!-- 页面导航 -->
    <ul v-show="nav.visible"
      class="page-nav">

      <li v-for="(item, index) in nav.options"
        :class="['page-nav-item', activeNav(index)]"
        :key="index"
        @click="toggleNav(index)">

        <span>{{ item.label }}
        </span>

      </li>

    </ul>

  </div>

</template>

<script>
import 'maptalks/dist/maptalks.css'
import * as maptalks from 'maptalks'

import { basket, metadata } from '@/configs/api'
import mapConfigs from '@/configs/map'

import appFiles from './files'

const debounce = (callback) => {
  let timer
  return function () {
    clearTimeout(timer)
    timer = setTimeout(() => {
      callback()
    }, 100)
  }
}

export default {
  name: 'app-metadata-detail',
  components: {
    'app-files': appFiles
  },
  data () {
    return {
      attachment: [
        {
          label: '数据文档',
          name: '9411411-菲律宾洪水灾害数据集-数据文档.docx',
          url: '/'
        },
        {
          label: '数据样例',
          name: '9411411-菲律宾洪水灾害数据集-数据样例-Aklan.docx',
          url: '/'
        }
      ],
      base: [
        {
          time: true,
          label: 'Time frame',
          prop: 'timeRange',
          value: '1980年1月至2019年12月'
        },
        {
          label: 'Area',
          prop: 'spaceArea',
          value: '中国近海'
        },
        {
          gis: true,
          label: 'Spatial scope',
          prop: 'spaceRange',
          value: '100°E~130°E，0°N~45°N'
        },
        {
          label: 'Parameter',
          prop: 'vars',
          value: '潜热通量，10米风速，2米气温，2米比湿，海表面温度'
        },
        {
          label: 'Project support',
          prop: 'supportProject',
          value: '国家自然科学基金'
        },
        {
          label: 'Temporal Resolution',
          prop: 'timeResolution',
          value: '1980年1月至2019年12月，月平均'
        },
        {
          label: 'Horizontal resolution',
          prop: 'horizontalResolution',
          value: '0.25°×0.25°'
        },
        {
          label: 'Vertical resolution',
          prop: 'verticalResolution',
          value: '-'
        },
        {
          label: 'Storage format',
          prop: 'storageFormat',
          value: 'nc'
        },
        {
          label: 'Keyword',
          prop: 'keywords',
          value: '洪水'
        }
      ],
      buffer: {
      },
      bar: [
        {
          color: 'rgba(255, 163, 15, 1)',
          datetime: true,
          icon: 'icon-time',
          label: 'Latest Update Time',
          prop: 'latestUpdateTime',
          suffix: '',
          value: '2020-12-17 09:16'
        },
        {
          color: 'rgba(218, 139, 101, 1)',
          icon: 'icon-fsb_datasize',
          label: 'Data Capacity',
          prop: 'Data Capacity',
          suffix: 'MB',
          value: 282.62
        },
        {
          color: 'rgba(201, 209, 146, 1)',
          icon: 'icon-view',
          label: 'Visit Count',
          prop: 'visitCount',
          suffix: 'time',
          value: 1872
        },
        {
          color: 'rgba(95, 112, 177, 1)',
          icon: 'icon-download',
          label: 'Download Count',
          prop: 'downloadCount',
          suffix: 'time',
          value: 1872
        }
      ],
      card: [
        // {
        //   label: 'Chinese Name',
        //   prop: 'cnName',
        //   value: '100m×100m空间分辨率的黑河流域中上游气候平均逐月降水量'
        // },
        {
          label: 'Name',
          prop: 'enName',
          value: 'The 100m×100m fine distribution of the climate mean annual precipitation in the upper and middle reaches of the Heihe River  Basin'
        },
        {
          label: 'DOI',
          link: true,
          prop: 'doi',
          value: 'http://dx.doi.org/10.12072/ncdc.Westdc.db0020.2020'
        },
        {
          label: 'CSTR',
          link: true,
          prop: 'cstr',
          value: 'CSTR:11738.11.ncdc.Westdc.2020.718'
        },
        {
          label: 'Sharing Mode',
          prop: 'shareMode',
          value: '离线申请'
        },
        {
          label: 'Classification',
          prop: 'dataClassify',
          value: '水文'
        }
      ],
      carousel: [
        {
          path: '/img/test.jpg'
        },
        {
          path: '/img/test.jpg'
        }
      ],
      contact: [
        {
          label: 'Data producer',
          prop: 'dataProducer',
          value: '张荣望'
        },
        {
          label: 'Company',
          prop: 'producerOrgan',
          value: '中国科学院南海海洋研究所'
        },
        {
          label: 'Contact information',
          prop: 'producerContactWay',
          value: 'rwzhang@qdio.ac.cn'
        },
        {
          label: 'Central contact',
          prop: 'centerLinkman',
          value: '张斌'
        },
        {
          label: 'Contact information',
          prop: 'centerContactWay',
          value: 'zhangbin@qdio.ac.cn'
        }
      ],
      description: [
        {
          label: 'Data source description',
          prop: 'datasourceDescribe',
          value: '高精度的DEM数据、黑河流域中上游地区的气候平均逐月降水量、地理地形因子'
        },
        {
          label: 'Data processing method',
          prop: 'dataProcessMethod',
          value: '统计分析'
        },
        {
          label: 'Data quality description',
          prop: 'dataQualityDescribe',
          value: '数据类型为单精度浮点型，无效值为-9'
        }
      ],
      detail: {
        contributor: '张超(中国科学院南海海洋研究所)',
        name: '菲律宾洪水灾害数据集',
        summary: '菲律宾洪水灾害数据集是菲律宾百年一遇洪水灾害数据与地图，文件的分辨率为10米，说明了如果实际降雨量超过了100年降雨返回期的降雨量时该区域的淹没程度。数据类型为矢量，数据文件为13个shp文件，数据量1.01GB。该数据可服务于全国海洋系统及涉海企业事业单位、共建高校及其相关学科研究人员与专业技术人员以及东盟十国相关部门和人员。'
      },
      download: {
        checkeds: [],
        list: [
          {
            checkbox: false,
            name: '返回上一级(../)',
            size: '-',
            time: '-',
            type: '文件夹'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198001.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198002.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198003.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198004.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198005.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198006.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198007.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198008.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198009.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          },
          {
            checkbox: true,
            name: 'CSFlux_v1.0_monthly_r0250×25_lhf_198010.nc',
            size: 0.4472,
            time: '2020-11-26 10:18',
            type: '文件'
          }
        ],
        path: [
          {
            label: '根目录',
            value: '/'
          },
          {
            label: 'CSFlux',
            value: '/CSFlux/'
          },
          {
            label: 'CSFlux_v1.0data_1980_1989',
            value: '/CSFlux/CSFlux_v1.0data_1980_1989'
          }
        ]
      },
      extra: [
        {
          label: 'Investigation Paltform',
          prop: 'surveyPaltform',
          value: 'Test Platform'
        },
        {
          label: 'Investigation Organization',
          prop: 'surveyInstitution',
          value: 'Test Institution'
        },
        {
          label: 'Instruments and Apparatuses',
          prop: 'instruments',
          value: 'Test Instruments'
        },
        {
          label: 'Subject Classification',
          prop: 'subjectClassify',
          value: 'Test Class'
        },
        {
          label: 'Manufacturer',
          prop: 'manufacturer',
          value: 'Test Manufacturer'
        }
      ],
      ftpDownloadUrl: '',
      geometry: null,
      id: '',
      layer: null,
      literature: [
      ],
      map: null,
      nav: {
        current: -1,
        disabled: false,
        options: [
          {
            anchor: 'metadata-title',
            label: '',
            top: 30
          },
          {
            anchor: 'metadata-summary',
            label: 'Abstract',
            top: 207
          },
          {
            anchor: 'metadata-detail-base',
            label: 'Metadata',
            top: 679
          },
          {
            anchor: 'metadata-detail-contact',
            label: 'Contact',
            top: 902
          },
          {
            anchor: 'metadata-usage-statement',
            label: 'Data usage statement',
            top: 1061
          },
          {
            anchor: 'metadata-tabs',
            diff: 0,
            label: 'Detailed description of data',
            tab: 'description',
            top: 1344
          },
          {
            anchor: 'metadata-tabs',
            diff: 1,
            label: 'Extended information',
            tab: 'extra',
            top: 1344
          },
          {
            anchor: 'metadata-tabs',
            diff: 2,
            label: 'Related achievements',
            tab: 'results',
            top: 1344
          },
          {
            anchor: 'metadata-tabs',
            diff: 3,
            label: 'Related literature',
            tab: 'related-literature',
            top: 1344
          },
          {
            anchor: 'dataset-download',
            label: 'Data download',
            top: 1598
          },
          {
            anchor: 'platform-statement',
            label: 'Platform statement',
            top: 2000
          }
        ],
        visible: false
      },
      statement: [
        {
          label: 'Statement content',
          prop: 'dataReferenceDeclare',
          value: [
            '中文表达方式：数据来源于海洋科学数据中心门户平台-中国科学院海洋研究所海洋大数据中心(http://msdc.qdio.ac.cn)。',
            '英文表达方式：The portal of Marine Science Data Center, Marine Big Data Center, Institute of Oceanology, the Chinese Academy of Sciences (CAS),  China (http:// msdc.qdio.ac.cn)。'
          ]
        },
        {
          label: 'Citation Method',
          prop: 'referenceMethod',
          value: [
            '文献引用方式：Li, Guancheng, L. Cheng*, J. Zhu*, K. E. Trenberth, M. E. Mann, J. P. Abraham, 2020: Increasing ocean stratification over the past half century. Nature Climate Change, in press. https://doi.org/10.1038/s41558-020-00918-2.'
          ]
        },
        {
          label: 'Thanks',
          prop: 'thankWay',
          value: [
            '本数据产品由国家重点研发计划全球变化及应对专项项目（2017YFA0603200）资助。 Supported by the National Key R&D Program of China (2017YFA0603200). 海洋科学大数据中心(http://msdc.qdio.ac.cn), 2020. doi: 10.12157/iocas.CSFlux.1980_2019'
          ]
        }
      ],
      results: {
        columns: [
          {
            label: 'Achievement Name',
            prop: 'name',
            tooltip: true
          },
          {
            label: 'Achievement Type',
            prop: 'type',
            width: 130
          },
          {
            label: 'Achievement Date',
            prop: 'time',
            width: 130
          },
          {
            label: 'Achievement Brief',
            prop: 'brief',
            tooltip: true
          }
        ],
        list: [
          // {
          //   brief: '通关海洋数据建模预测海洋环境要素的预报',
          //   id: '1',
          //   name: '关于海洋环境变化的研究',
          //   time: '2021-01-09',
          //   type: '论文'
          // }
        ]
      },
      tabs: {
        current: 'description',
        list: [
          {
            key: 'description',
            label: 'Detailed description of data'
          },
          {
            key: 'extra',
            label: 'Extended information'
          },
          {
            key: 'results',
            label: 'Related achievements'
          },
          {
            key: 'related-literature',
            label: 'Related literature'
          }
        ]
      }
    }
  },
  watch: {
    $route: 'fetchData'
  },
  mounted () {
    window.addEventListener('scroll', debounce(this.scrollPage))
    this.calcNavPosition()
    this.initMap()
    this.fetchData()
  },
  destroyed () {
    window.removeEventListener('scroll', debounce(this.scrollPage))
  },
  methods: {
    addToBasket () {
      const params = new FormData()
      params.append('metadataId', this.id)
      params.append('datasetType', this.buffer.productionClassify)
      params.append('searchContent', '')

      this.axios.post(basket.add, params)
        .then((_http) => {
          const code = _http.data.code
          if (code === 200) {
            this.$message.success('数据提交成功')
            Object.assign(this.model, this.$options.data().model)
            this.toggleDrawer()
            const token = localStorage.getItem('authToken')
            this.$store.dispatch('loadBasket', { token })
          } else {
            const message = _http.data.message || _http.data.msg
            this.$message.error(message || '数据提交失败')
          }
        })
    },
    activeNav (index) {
      return this.nav.current === index ? 'active' : ''
    },
    activeTab (key) {
      return this.tabs.current === key ? 'active' : ''
    },
    calcNavPosition () {
      this.$nextTick(() => {
        const options = this.nav.options
        options.forEach((item, index) => {
          const navItem = document.querySelector(`#${item.anchor}`)
          if (navItem && navItem.offsetTop) {
            options[index].top = navItem.offsetTop
          }
        })
      })
    },
    fetchData () {
      const query = this.$route.query
      if (query.id) {
        this.id = query.id
        this.loadData()
      }
    },
    initMap () {
      this.map = new maptalks.Map('thumbnail', {
        attribution: '',
        baseLayer: new maptalks.TileLayer(
          'vector',
          mapConfigs.getBase('vector')
        ),
        bearing: 0,
        center: [143.283294, 23.720231],
        zoom: 2
      })
      this.layer = new maptalks.VectorLayer('station').addTo(this.map)
    },
    loadData () {
      const params = new FormData()
      params.append('Id', this.id)

      this.axios.post(metadata.info, params)
        .then((_http) => {
          const code = _http.data.code
          if (code === 200) {
            const data = _http.data.data
            // doi访问地址
            data.doi = data.doi ? `http://dx.doi.org/${data.doi}` : ''
            // ftp文件
            this.ftpDownloadUrl = data.ftpDownloadUrl.slice()
            this.$refs['app-files'].loadData(this.ftpDownloadUrl)
            // 缓存数据
            Object.assign(this.buffer, data)
            // 附件
            if (data.accessoriesList.length) {
              this.attachment = data.accessoriesList.map((item) => {
                const name = item.filePath.replace(/.*\//, '')
                const label = name.replace(/-\d+\.\w+/, '')
                return {
                  label,
                  name,
                  url: this.$baseURL + item.filePath
                }
              })
            }
            // 顶部数据带
            this.bar.forEach((item, index) => {
              const value = data[item.prop]
              if (item.datetime) {
                this.bar[index].value = (value || '').replace(/T/, ' ')
                  .replace(/(.*\s\d{2}:\d{2}).*/, '$1')
              } else {
                this.bar[index].value = value
              }
            })
            // 基础信息
            this.base.forEach((item, index) => {
              if (item.gis) {
                // 空间范围
                this.base[index].value = `${data.longitude}，${data.latitude}`
              } else if (item.time) {
                // 时间范围
                const timeRange = [
                  (data.dataBeginTime).replace(/^(\d{4})-(\d{2}).*/, '$1.$2'),
                  (data.dataEndTime).replace(/^(\d{4})-(\d{2}).*/, '$1.$2')
                ].join('-')
                this.base[index].value = timeRange
              } else {
                this.base[index].value = data[item.prop] || '-'
              }
            })
            // 数据卡
            this.card.forEach((item, index) => {
              this.card[index].value = data[item.prop] || '-'
            })
            // 插图轮播
            if (data.imagesList.length) {
              this.carousel = data.imagesList.map((item) => {
                return {
                  path: this.$baseURL + item.filePath
                }
              })
            }
            // 联系信息
            this.contact.forEach((item, index) => {
              this.contact[index].value = data[item.prop] || '-'
            })
            // 数据描述
            this.description.forEach((item, index) => {
              this.description[index].value = data[item.prop] || '-'
            })
            // 贡献者 & 名称 & 摘要
            this.detail.contributor = `${data.dataContributor}(${data.manufacturer})`
            this.detail.name = data.enName
            this.detail.summary = data.dataBrief
            // 扩展信息
            this.extra.forEach((item, index) => {
              this.extra[index].value = data[item.prop] || '-'
            })
            // 申明
            this.statement.forEach((item, index) => {
              this.statement[index].value = (data[item.prop] || '-')
                .replace(/(\s{2,})/g, '@@')
                .split('@@')
            })
            // 引用说明
            if (data.pertinentLiterature) {
              this.literature = data.pertinentLiterature
                .replace(/(\s{2,})/g, '@@')
                .split('@@')
            }
            // 空间位置
            this.updateMap(data.longitude, data.latitude)
            // 导航位置更新
            this.calcNavPosition()
          }
        })
    },
    scrollPage () {
      if (this.nav.disabled) {
        return null
      }

      const offsetY = window.scrollY - 200
      const options = this.nav.options
      const limit = options.length
      const tab = this.tabs.current

      for (let i = 1; i < limit; i++) {
        const item = options[i]
        const diff = item.diff || 0
        const last = options[i - diff - 1]
        // 确定滚动高度
        const row = item.top > offsetY && last.top < offsetY
        // 确定 tabs 激活项
        const column = item.tab ? item.tab === tab : true
        if (row && column) {
          this.nav.current = i
          this.nav.visible = i + 1 < limit
          return null
        }
      }

      // 无匹配的选项，初始化导航器
      this.nav.current = -1
      this.nav.visible = false
    },
    toggleFile (index, chekced) {
      this.download.list[index].chekced = !chekced
    },
    toggleFileAll (checked) {
      this.download.checkeds = []

      if (checked) {
        this.download.checkeds = this.download.list.map((item) => item.name)
      }
    },
    toggleNav (index) {
      const data = this.nav.options[index]
      if (index) {
        this.nav.current = index
      } else {
        this.nav.current = -1
      }
      const anchor = document.querySelector(`#${data.anchor}`)
      if (anchor) {
        this.nav.disabled = true
        setTimeout(() => {
          this.nav.disabled = false
        }, 1000)

        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        })
      }
      if (data.tab) {
        this.toggleTab(data.tab)
      }
    },
    toggleTab (key) {
      this.tabs.current = key
      this.scrollPage()
    },
    updateMap (longitude, latitude) {
      if (this.geometry) {
        this.geometry.remove()
        this.geometry = null
      }

      const numRE = /^\d+(\.\d+)*$/
      const numsRE = /^\d+(\.\d+)*~\d+(\.\d)*$/
      if (numRE.test(latitude) && numRE.test(longitude)) {
        // 点位
        longitude -= 0
        latitude -= 0
        this.geometry = new maptalks.Marker(
          [longitude, latitude],
          {
            symbol: {
              markerType: 'pin',
              markerFill: 'rgb(135,196,240)',
              markerFillOpacity: 1,
              markerLineColor: '#34495e',
              markerLineWidth: 3,
              markerLineOpacity: 1,
              markerLineDasharray: [],
              markerWidth: 40,
              markerHeight: 40,
              markerDx: 0,
              markerDy: 0,
              markerOpacity: 1
            }
          }
        ).addTo(this.layer)
        this.map.setCenter(new maptalks.Coordinate(longitude, latitude))
      } else if (numsRE.test(latitude) && numsRE.test(longitude)) {
        // 区域
        const lat = latitude.split('~').map((item) => item - 0)
        const lon = longitude.split('~').map((item) => item - 0)
        const xmin = lon[0]
        const xmax = lon[1]
        const ymin = lat[0]
        const ymax = lat[1]
        this.geometry = new maptalks.Polygon(
          [
            [xmin, ymin],
            [xmin, ymax],
            [xmax, ymax],
            [xmax, ymin],
            [xmin, ymin]
          ],
          {
            symbol: {
              polygonFill: 'rgb(135,196,240)',
              polygonOpacity: 1,
              lineColor: '#1bbc9b',
              lineWidth: 6,
              lineJoin: 'round',
              lineCap: 'round',
              lineDasharray: null,
              lineOpacity: 1
            }
          }
        ).addTo(this.layer)
        this.map.fitExtent(new maptalks.Extent(
          xmin, ymin, xmax, ymax
        ), 0)
      }
    }
  }
}
</script>

<style scoped>
/* 0. 容器 */
.app-metadata-detail {
  background-color: transparent;
  margin-top: 50px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  /*padding-top: 20px;*/
  position: relative;
}

/* 1. 正文 */
.metadata-content {
  background-color: white;
  text-align: left;
  box-sizing: border-box;
  margin-bottom: 40px;
  min-height: calc(100vh - 200px - 164px);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  width: 1200px;
}
/* 1.0. 通用内容 */
.metadata-section-header {
  height: 20px;
  line-height: 20px;
  text-align: left;
}
.metadata-section-cude {
  color: black;
  display: inline-block;
  font-size: 6px;
  height: 20px;
  margin-right: 3px;
  transform: translateY(-2px);
}
.metadata-section-icon {
  border-left-color: rgba(16, 90, 190, 1);
  border-left-style: solid;
  border-left-width: 2px;
  border-right-color: rgba(16, 90, 190, 1);
  border-right-style: solid;
  border-right-width: 8px;
  display: inline-block;
  height: 20px;
  margin-right: 8px;
  width: 12px;
}
.metadata-subtitle {
  color: rgba(16, 90, 190, 1);
  font-size: 18px;
}
/* 1.1. 标题 */
#metadata-title {
  color: rgba(16, 90, 190, 1);
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}
/* 1.2. 数据贡献者 */
#metadata-contributor {
  color: rgba(169, 169, 169, 1);
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}
#metadata-contributor .label {
  font-weight: 600;
}
/* 1.3. 数据信息条 */
#metadata-detail-bar {
  font-size: 12px;
  margin-bottom: 13px;
  margin-top: 13px;
  padding-bottom: 5px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
}
#metadata-detail-bar li {
  display: inline-block;
  list-style: none;
  padding-left: 65px;
  position: relative;
  width: 25%;
}
#metadata-detail-bar li:first-child {
  border-left: none;
}
#metadata-detail-bar .icon {
  border-radius: 50%;
  color: white;
  font-size: 20px;
  height: 40px;
  left: 5px;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 40px;
}
#metadata-detail-bar p {
  line-height: 25px;
}
/* 1.4. 数据信息卡 */
#metadata-detail-card {
  background-color: rgba(247, 247, 247, 1);
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
}
#metadata-detail-card li {
  border-bottom-color: rgba(221, 221, 221, 1);
  border-bottom-style: dashed;
  border-bottom-width: 1px;
  list-style: none;
  padding-bottom: 5px;
  padding-left: 100px;
  padding-top: 5px;
  position: relative;
}
#metadata-detail-card .label {
  left: 0px;
  position: absolute;
  top: 5px;
  width: 100px;
}
/* 1.5. 基本信息 & 数据联系信息 */
#metadata-detail-base,
#metadata-detail-contact {
  margin-top: 50px;
  min-height: 139px;
  position: relative;
}
#metadata-detail-base ul,
#metadata-detail-contact ul {
  column-count: 2;
  column-gap: 60px;
  font-size: 12px;
  padding-left: 20px;
  width: 1000px;
}
#metadata-detail-base li,
#metadata-detail-contact li {
  color: rgba(102, 102, 102, 1);
  list-style: none;
  padding-bottom: 5px;
  padding-left: 125px;
  padding-top: 5px;
  position: relative;
  vertical-align: top;
}
#metadata-detail-base li:first-child,
#metadata-detail-content li:first-child {
  boer-top: none;
}
#metadata-detail-base .label,
#metadata-detail-contact .label {
  color: black;
  font-weight: 600;
  left: 0px;
  position: absolute;
  top: 5px;
  width: 134px;
}
/* 1.6. 数据摘要 */
#metadata-summary {
  margin-top: 20px;
}
.metadata-summary-content {
  margin-top: 10px;
  padding-left: 20px;
  word-break:break-all
}
.metadata-controls {
  margin-top: 15px;
}
/* 1.7 Tabs */
#metadata-tabs {
  margin-bottom: 10px;
  margin-top: 60px;
}
.tabs-header {
  display: flex;
  padding-left: 0px;
  text-align: center;
}
.tabs-header li {
  border-bottom-color: lightgray;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  cursor: pointer;
  flex-grow: 1;
  list-style: none;
  padding-left: 20px;
  padding-right: 20px;
}
.tabs-header li.active {
  border-bottom-color: dodgerblue;
  color: dodgerblue;
  cursor: text;
}
/* 1.7.1.  数据详细描述 */
#metadata-description {
  margin-top: 20px;
}
#metadata-description ul {
  padding-left: 0px;
}
#metadata-description li {
  list-style: none;
  margin-top: 10px;
}
#metadata-description .subtitle {
  background-color: ghostwhite;
  border-left-color: blue;
  border-left-style: solid;
  border-left-width: 3px;
  padding-left: 5px;
}
#metadata-description p {
  text-indent: 4ch;
  margin-top: 5px;
}
/* 1.7.2. 数据使用申明 */
#metadata-usage-statement {
  margin-top: 20px;
}
#metadata-usage-statement ul {
  padding-left: 20px;
}
#metadata-usage-statement li {
  list-style: none;
  padding-left: 135px;
  position: relative;
  margin-top: 10px;
}
#metadata-usage-statement .subtitle {
  left: 0px;
  position: absolute;
}
#metadata-usage-statement .subtitle::after {
  content: ':';
}
#metadata-usage-statement p {
  /*text-indent: 4ch;*/
  margin-top: 5px;
}
#metadata-usage-statement.metadata-usage-statement li {
  padding-left: 200px;
}
/* 1.7.3. 数据附件 */
#metadata-attachment {
  margin-top: 20px;
}
#metadata-attachment-list {
  margin-top: 10px;
  padding-left: 20px;
}
#metadata-attachment-list li {
  list-style: none;
  margin-top: 5px;
}
#metadata-attachment-list p {
  padding-left: 20px;
}
#metadata-attachment-list a {
  float: right;
}
/* 1.7.4. 相关文献 */
#metadata-related-literature {
  margin-top: 20px;
}
#metadata-related-literature .empty {
  color: red;
  margin-bottom: 20px;
  margin-top: 20px;
  text-indent: 4ch;
}
/* 1.8. 数据下载 */
#dataset-download {
  margin-top: 50px;
}
.download-toolbar {
  margin-bottom: 5px;
  margin-top: 5px;
  position: relative;
}
.download-path {
  display: inline;
}
.download-path li {
  color: dodgerblue;
  cursor: pointer;
  display: inline;
  line-height: 28px;
  list-style: none;
  margin-right: 20px;
  padding-left: 0px;
  position: relative;
}
.download-path li::after {
  color: darkgray;
  content: '>';
  position: absolute;
  right: -10px;
}
.download-path li:last-child {
  color: darkgray;
  cursor: text;
  margin-right: 0px;
}
.download-path li:last-child::after {
  content: '';
  right: 0px;
}
.download-controls {
  float: right;
}
.download-list .name {
  display: inline-block;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  width: 630px;
}
.download-list .base {
  overflow: hidden;
  text-overflow: ellipsis;
}
.download-list .popup {
  background-color: white;
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  display: block;
  opacity: 0;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  position: absolute;
  top: 25px;
  visibility: hidden;
  right: 0px;
  z-index: 9;
}
.download-list .name:hover .popup {
  opacity: 1;
  transition-delay: 1s;
  transition-duration: .3s;
  visibility: visible;
}
.download-list .icon {
  height: 18px;
  transform: translateY(4px);
  width: 18px;
}
.download-list .size {
  display: inline-block;
  text-align: center;
  width: 75px;
}
.download-list .type {
  display: inline-block;
  text-align: center;
  width: 52px;
}
.download-list .time {
  display: inline-block;
  text-align: center;
  width: 125px;
}
.download-folder {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 21px;
  padding-left: 24px;
  width: 100%;
}
.download-folder .name {
  color: dodgerblue;
  cursor: pointer;
}
.download-more {
  border-top-color: ghostwhite;
  border-top-style: solid;
  border-top-width: 1px;
  cursor: pointer;
  line-height: 28px;
  margin-top: 10px;
  text-align: center;
}
/* 1.9. 平台申明 */
#platform-statement {
  margin-top: 20px;
}
#platform-statement ul {
  background-color: rgba(250, 236, 227, 0.3);
  border-color: rgba(254, 235, 225, 1);
  border-style: solid;
  border-width: 1px;
  color: rgba(255, 72, 29, 1);
  margin-top: 20px;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
}
#platform-statement li {
  list-style: '*';
  margin-top: 10px;
  padding-left: 10px;
}
/* 1.10 相关成果 */
#related-results {
  margin-bottom: 10px;
  margin-top: 20px;
}
#related-results h3 {
  margin-bottom: 10px;
}

/* 2.1. 轮播图 */
.metadata-carousel {
  background-color: aliceblue;
  height: 139px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 180px;
}
/* 2.2. 缩略图 */
.metadata-thumbnail {
  background-color: aliceblue;
  height: 139px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 180px;
}

/* 页面导航 */
.page-nav {
  left: calc(50vw - 820px);
  padding-right: 65px;
  position: fixed;
  text-align: right;
  top: 400px;
  width: 235px;
}
.page-nav::after {
  background-color: rgba(234, 234, 234, 1);
  content: ' ';
  height: calc(100% - 30px);
  position: absolute;
  right: 49px;
  top: 15px;
  width: 2px;
  z-index: 0;
}
.page-nav-item {
  color: rgba(51, 51, 51, 1);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
  list-style: none;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
}
.page-nav-item.active span {
  background-color: rgba(64, 158, 255, 1);
  color: white;
  display: inline-block;
  line-height: 20px;
  padding-left: 8px;
  position: relative;
}
.page-nav-item.active span::after {
  border-bottom-color: transparent;
  border-left-color: rgba(64, 158, 255, 1);
  border-right-color: transparent;
  border-top-color: transparent;
  border-style: solid;
  border-width: 10px;
  box-sizing: border-box;
  content: ' ';
  height: 20px;
  position: absolute;
  right: -20px;
  top: 0px;
  width: 10px;
}
.page-nav-item::after {
  background-color: rgba(209, 209, 209, 1);
  border-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  content: ' ';
  height: 10px;
  position: absolute;
  right: -20px;
  top: 5px;
  width: 10px;
  z-index: 1;
}
.page-nav-item:first-child:after,
.page-nav-item:last-child:after {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(209, 209, 209, 1);
}
.page-nav-item.active::after {
  background-color: rgba(64, 158, 255, 1);
  border-color: rgba(255, 255, 255, 1);
}
</style>
