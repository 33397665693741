<template>

  <div
    class="app-prod-file">

    <div
      class="section-gap">

      <i
        class="section-gap-icon">
      </i>

      <p
        class="section-gap-content">Data download
      </p>

      <ul
        class="file-breadcrumb">

        <li
          v-for="(item, index) in breadcrumb"
          class="file-breadcrumb-item"
          :key="index">

          <span
            v-show="item.prefix"
            class="file-breadcrumb-gap">>
          </span>

          <span
            class="file-breadcrumb-content"
            @click="loadData(item.url)">{{ item.label }}
          </span>

        </li>

      </ul>

    </div>

    <!-- <div
      class="prod-file-title">数据产品数据下载
    </div> -->

    <ul
      class="prod-file-list">

      <li
        class="list-header">

        <p
          class="prod-file-name">File name
        </p>

        <p
          class="prod-file-size">File size(M)
        </p>

        <p
          class="prod-file-type">File type
        </p>

        <p
          class="prod-file-datetime">Last modified time
        </p>

      </li>

      <li
        v-for="(item, index) in list"
        class="record"
        :key="index">

        <span
          v-if="item.directory"
          class="prod-file-download prod-file-name"
          @click="loadData(item.url)">

          <img
            alt="ICON_FOLDER"
            class="prod-file-icon"
            src="/img/data/files/folder.png">

          <span>{{ item.name }}
          </span>

        </span>

        <span
          v-else
          class="prod-file-download prod-file-name"
          @click="downloadFile(item.url)">

          <img
            alt="ICON_FILE"
            class="prod-file-icon"
            src="/img/data/files/file.png">

          <span>{{ item.name }}
          </span>

        </span>

        <p
          class="prod-file-size">{{ item.size }}
        </p>

        <p
          class="prod-file-type">{{ item.type }}
        </p>

        <p
          class="prod-file-datetime">{{ item.datetime }}
        </p>

      </li>

    </ul>

  </div>

</template>

<script>
import * as api from '@/configs/api'
import util from '@/util/index'

export default {
  name: 'app-main',
  props: {
    url: {
      type: String,
      required: true,
      default: ''
    }
  },
  data () {
    return {
      breadcrumb: [
        {
          label: 'root directory',
          prefix: false,
          url: 'ltgkserver/product1'
        }
      ],
      list: [],
      prevUrl: null
    }
  },
  watch: {
    // $route: 'loadData',
    // url: 'loadData'
  },
  mounted () {
    // this.loadData()
  },
  methods: {
    downloadFile (url) {
      const requestURL = [
        this.baseURL,
        api.subject.download,
        '?filePath=',
        encodeURI(url.replace(/.*products/, 'products'))
      ].join('')

      window.open(requestURL, '_blank')
    },
    loadData (current) {
      const params = new FormData()
      const url = (current || this.url).replace(/`/g, '/')

      if (url === this.url) {
        this.prevUrl = null
      } else {
        this.prevUrl = url.replace(/(\/[A-Za-z0-9-.]*)$/, '')
      }

      params.append('url', url)

      this.updateBreadcrumb(url)

      this.axios.post(api.subject.files, params)
        .then((response) => {
          const baseURL = decodeURI(url)
          const res = response.data

          if (res.code === 200 && res.data) {
            this.list = res.data.map(item => {
              item.datetime = util.datetimeFormating(item.timestamp)
              if (item.directory) {
                item.size = '-'
                item.type = 'folder'
                item.url = `${baseURL}/${item.name}`
              } else {
                item.size = (item.size / 1024 / 1024).toFixed(4)
                item.type = 'file'
                item.url = `ftp://${url}/${item.name}`
              }
              return item
            })

            if (this.prevUrl) {
              this.list.unshift({
                directory: true,
                name: '../',
                size: '-',
                type: 'Go Back',
                url: `${this.prevUrl}`
              })
            }
          }
        })
    },
    updateBreadcrumb (url) {
      const list = url.split('/')
      let base = this.url

      this.breadcrumb = [
        {
          label: 'root directory',
          prefix: false,
          url: this.url
        }
      ]

      list.forEach((item, index) => {
        if (index > 1) {
          base = `${base}/${item}`
          this.breadcrumb.push({
            label: item,
            prefix: true,
            url: base
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.app-prod-file {
  background-color: white;
  width: 100%;
}

.prod-file-title {
  border-bottom-color: #DCDFE6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.section-gap {
  margin-bottom: 30px;
  position: relative;
  text-align: left;
}
.section-gap-icon {
  border-bottom: none;
  border-left-color: rgba(16, 90, 190, 1);
  border-left-style: solid;
  border-left-width: 2px;
  border-right-color: rgba(16, 90, 190, 1);
  border-right-style: solid;
  border-right-width: 7px;
  border-top: none;
  display: inline-block;
  height: 20px;
  position: relative;
  top: 4px;
  width: 12px;
}
.section-gap-content {
  color: rgba(16, 90, 190, 1);
  display: inline;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.file-breadcrumb {
  display: inline-block;
  margin-left: 15px;
  padding-left: 0px;
}
.file-breadcrumb-item {
  display: inline;
  font-size: 12px;
  list-style: none;
}
.file-breadcrumb-gap {
  color: lightgray;
}
.file-breadcrumb-content {
  color: #105ABE;
  cursor: pointer;
}
.file-breadcrumb-item:last-child .file-breadcrumb-content {
  color: darkgray;
  cursor: text;
}

.prod-file-list {
  padding-left: 20px;
}

.list-header {
  line-height: 40px;
  list-style: none;
  padding-left: 0px;
}
.list-header > * {
  font-size: 14px;
  /* font-weight: 600; */
}

.record {
  font-size: 12px;
  line-height: 32px;
  list-style: none;
  padding-left: 0px;
}

.prod-file-download {
  color: dodgerblue;
  cursor: pointer;
  text-decoration: none;
}

.prod-file-name {
  display: inline-block;
  text-align: left;
  width: 480px;
}
.prod-file-size {
  display: inline-block;
  width: 100px;
}
.prod-file-type {
  display: inline-block;
  width: 100px;
}
.prod-file-datetime {
  display: inline-block;
  width: 200px;
}
.prod-file-icon {
  height: 18px;
  transform: translateY(4px);
  width: 18px;
}
</style>
